var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{attrs:{"id":"map"}},[_c('section',{staticClass:"hero is-fullheight loader-container"},[_c('div',{staticClass:"hero-body has-text-centered"},[_c('div',{staticClass:"container"},[_c('p',[_vm._v("Loading...")]),_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-2"},[_c('b-progress',{staticClass:"mt-2",attrs:{"size":"is-small","type":"is-info"}})],1)])])])])]),_c('div',{staticClass:"overlay-container"},[_c('div',{staticClass:"columns p-5"},[_c('div',{staticClass:"column is-mobile"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"p-2"},[(_vm.$route.query.id)?_c('p',{staticClass:"text-heading is-5"},[_vm._v(" Update Mileage Ignore Zone ")]):_c('p',{staticClass:"text-heading is-5"},[_vm._v("New Mileage Ignore Zone")]),(!_vm.mileageIgnoreZone.isCreated)?_c('div',[_vm._m(0),_c('b-tabs',{staticClass:"mt-2",model:{value:(_vm.selectedSearchType),callback:function ($$v) {_vm.selectedSearchType=$$v},expression:"selectedSearchType"}},[_c('b-tab-item',{attrs:{"value":"address","label":"Address"}},[_c('b-input',{staticClass:"mt-4",attrs:{"id":"places-search","placeholder":"Search address to create Mileage Ignore Zone","icon":"magnify","type":"search"},model:{value:(_vm.placesSearchQuery),callback:function ($$v) {_vm.placesSearchQuery=$$v},expression:"placesSearchQuery"}})],1),_c('b-tab-item',{attrs:{"value":"coordinates","label":"Coordinates"}},[_c('b-input',{staticClass:"mt-4",attrs:{"placeholder":"e.g. 12.34, 98.76 (lat, lng)","icon":"magnify","type":"search"},model:{value:(_vm.cooridnatesQuery),callback:function ($$v) {_vm.cooridnatesQuery=$$v},expression:"cooridnatesQuery"}}),(
                      _vm.cooridnatesQuery != '' &&
                        !_vm.validation.hasValidCoordinates(_vm.cooridnatesQuery)
                    )?_c('b-message',{staticClass:"mt-4",attrs:{"type":"is-warning","size":"is-small","has-icon":"","icon":"information-outline"}},[_vm._v(" Please enter valid coordinates,"),_c('br'),_vm._v(" e.g. 12.34567, 98.76543 (lat, lng) ")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.mileageIgnoreZone.isCreated)?_c('div',[_c('hr'),_c('form',{attrs:{"id":"submitMileageIgnoreZoneForm"},on:{"submit":function($event){$event.preventDefault();return _vm.submitMileageIgnoreZone.apply(null, arguments)}}},[_c('div',[_c('b-field',{attrs:{"label":"Name"}},[_c('b-input',{attrs:{"type":"text","required":"","placeholder":"Enter name"},model:{value:(_vm.mileageIgnoreZone.name),callback:function ($$v) {_vm.$set(_vm.mileageIgnoreZone, "name", $$v)},expression:"mileageIgnoreZone.name"}})],1)],1)]),(_vm.$route.query.id)?_c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column is-narrow pr-0"},[_c('router-link',{attrs:{"to":{
                      name: 'MileageIgnoreZones',
                      query: { id: _vm.$route.query.id }
                    }}},[_c('b-button',{attrs:{"type":"is-outline is-dark"}},[_vm._v("Cancel")])],1)],1),_vm._m(1)]):_c('div',{staticClass:"columns mt-5"},[_c('div',{staticClass:"column is-narrow pr-0"},[_c('b-button',{attrs:{"type":"is-outline is-dark"},on:{"click":_vm.discardMileageIgnoreZone}},[_vm._v("Discard")])],1),_vm._m(2)])]):_vm._e()])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"mt-2"},[_vm._v(" Click on the map to create a Mileage Ignore Zone "),_c('br'),_vm._v("or search via: ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column is-narrow"},[_c('button',{staticClass:"button is-info",attrs:{"type":"submit","form":"submitMileageIgnoreZoneForm"}},[_vm._v(" Update ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column is-narrow"},[_c('button',{staticClass:"button is-info",attrs:{"type":"submit","form":"submitMileageIgnoreZoneForm"}},[_vm._v(" Save ")])])
}]

export { render, staticRenderFns }