var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.selectedTenant)?_c('div',[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-9 is-12-mobile p-5"},[_c('div',{staticClass:"pl-3 pt-3"},[_c('div',{staticClass:"columns is-vcentered"},[_vm._m(0),_c('div',{staticClass:"column is-narrow"},[_c('router-link',{attrs:{"to":{
                  name: 'MileageIgnoreZoneMap',
                  query: { selectedTenant: this.$route.query.selectedTenant }
                }}},[_c('b-button',{attrs:{"type":"is-info","icon-left":"plus"}},[_vm._v("Add Mileage Ignore Zone")])],1)],1)]),_c('hr'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-narrow"},[_vm._m(1),_c('b-select',{staticClass:"ml-2",attrs:{"placeholder":"Sort by"},on:{"input":function($event){return _vm.filterMileageIgnoreZones()}},model:{value:(_vm.filters.sort),callback:function ($$v) {_vm.$set(_vm.filters, "sort", $$v)},expression:"filters.sort"}},[_c('option',{attrs:{"value":"ascending"}},[_vm._v("Date Created (Ascending)")]),_c('option',{attrs:{"value":"descending"}},[_vm._v("Date Created (Descending)")])])],1),_c('div',{staticClass:"column"}),_c('div',{staticClass:"column is-one-quarter mt-5"},[_c('div',{staticClass:"field mt-2"},[_c('p',{staticClass:"control has-icons-left"},[_c('b-input',{attrs:{"placeholder":"Search by name","icon":"magnify","type":"search"},on:{"input":function($event){return _vm.filterMileageIgnoreZones()}},model:{value:(_vm.filters.searchQuery),callback:function ($$v) {_vm.$set(_vm.filters, "searchQuery", $$v)},expression:"filters.searchQuery"}})],1)])])]),(
              _vm.filteredMileageIgnoreZones && _vm.filteredMileageIgnoreZones.length
            )?_c('b-table',{attrs:{"data":_vm.filteredMileageIgnoreZones,"paginated":_vm.tableProps.isPaginated,"per-page":_vm.tableProps.perPage,"current-page":_vm.tableProps.currentPage,"pagination-simple":_vm.tableProps.isPaginationSimple,"pagination-position":_vm.tableProps.paginationPosition,"default-sort-direction":_vm.tableProps.defaultSortDirection,"sort-icon":_vm.tableProps.sortIcon,"sort-icon-size":_vm.tableProps.sortIconSize,"selected":_vm.selected,"aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"},on:{"update:selected":function($event){_vm.selected=$event},"click":_vm.showDetail}},[_c('b-table-column',{attrs:{"field":"name","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.name)+" ")]}}],null,false,2982927002)}),_c('b-table-column',{attrs:{"field":"createdAt","label":"Date Created"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.startDate === null)?_c('p',[_vm._v("-")]):_c('p',[_vm._v(" "+_vm._s(_vm._f("date")(props.row.createdAt))+" ")])]}}],null,false,2556682258)})],1):_c('div',[_c('i',{staticClass:"mdi mdi-information-outline pr-2"}),_vm._v("No Mileage Ignore Zones to display. ")])],1)]),_c('div',{staticClass:"column is-3 is-hidden-mobile"},[_c('MileageIgnoreZonesRightSidebar',{attrs:{"mileageIgnoreZone":this.selected},on:{"sidebar-closed":_vm.removeSelection}})],1)])]):_c('div',[_c('NoTenantSelectedMessage')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column"},[_c('h1',{staticClass:"title is-5 mt-2"},[_vm._v("Mileage Ignore Zones")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"column pb-2 pt-0"},[_c('p',[_vm._v("Sort by")])])
}]

export { render, staticRenderFns }