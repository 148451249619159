var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sidebar-right is-hidden-mobile"},[(this.mileageIgnoreZone)?_c('div',[_c('div',{staticClass:"p-5"},[_c('div',[_c('div',{staticClass:"columns mb-0"},[_c('div',{staticClass:"column pb-0"},[_c('p',{staticClass:"is-size-5"},[_vm._v(" "+_vm._s(_vm.mileageIgnoreZone.name)+" ")]),_c('p',{staticClass:"text-small"},[_vm._v(" Added on "+_vm._s(_vm._f("date")(_vm.mileageIgnoreZone.createdAt))+" ")]),_c('p',{staticClass:"text-small"},[_vm._v(" Last updated on "+_vm._s(_vm._f("date")(_vm.mileageIgnoreZone.updatedAt))+" ")])]),_c('div',{staticClass:"column has-text-right pb-0"},[_c('button',{staticClass:"button button-round",on:{"click":() => {
                  this.$emit('sidebar-closed');
                }}},[_c('i',{staticClass:"mdi mdi-close"})]),_c('div',{staticClass:"mt-4"},[_c('router-link',{attrs:{"to":{
                  name: 'MileageIgnoreZoneMap',
                  query: {
                    id: _vm.$route.query.id,
                    selectedTenant: this.$route.query.selectedTenant
                  },
                  params: { mileageIgnoreZoneDetail: this.mileageIgnoreZone }
                }}},[_c('i',{staticClass:"mdi mdi-pencil pr-1 is-info"})]),_c('a',[_c('span',{staticClass:"icon",on:{"click":function($event){return _vm.deleteMileageIgnoreZone(_vm.$route.query.id)}}},[_c('i',{staticClass:"mdi mdi-delete pr-1 has-text-danger"})])])],1)])])])]),_c('div',{class:{ mapDisplay: _vm.mileageIgnoreZone },attrs:{"id":"map"}})]):_c('div',[_vm._m(0)]),_c('b-modal',{attrs:{"active":_vm.deleteMileageIgnoreZoneToggle,"has-modal-card":""},on:{"update:active":function($event){_vm.deleteMileageIgnoreZoneToggle=$event}}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Delete Geo-Fence")])]),_c('section',{staticClass:"modal-card-body"},[_c('p',[_vm._v("Are you sure you want to delete this Geo-Fence?")])]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":function($event){_vm.deleteMileageIgnoreZoneToggle = false}}},[_vm._v(" Close ")]),_c('button',{staticClass:"button is-danger",on:{"click":function($event){return _vm.deleteMileageIgnoreZone()}}},[_vm._v(" Delete Geo-Fence ")])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"hero-body has-text-centered sidebar-right-empty"},[_c('div',{staticClass:"container"},[_c('img',{attrs:{"src":require("@/assets/img/sidebar-right-idle-icon.svg")}}),_c('p',[_vm._v("Select a Geo-fence to view details")])])])])
}]

export { render, staticRenderFns }