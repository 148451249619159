
import Vue from "vue";
import gmapsInit from "@/services/mapService";
import notification from "@/services/notificationService";

export default Vue.extend({
  name: "MileageIgnoreZonesRightSidebar",
  props: {
    mileageIgnoreZone: Object
  },
  data() {
    return {
      deleteMileageIgnoreZoneToggle: false,
      map: undefined as undefined | any
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    }
  },
  watch: {
    mileageIgnoreZone: function() {
      if (this.mileageIgnoreZone) {
        this.loadMap();
      }
    }
  },
  methods: {
    openDeleteMileageIgnoreZoneModal() {
      this.deleteMileageIgnoreZoneToggle = true;
    },
    getMileageIgnoreZones() {
      this.$store.dispatch("getMileageIgnoreZones", {
        tenant: this.selectedTenant
      });
    },
    deleteMileageIgnoreZone(id) {
      const request = {
        tenant: this.selectedTenant,
        mileageIgnoreZoneId: id
      };
      this.$store.dispatch("deleteMileageIgnoreZone", request).then(() => {
        this.getMileageIgnoreZones();
      });
    },
    async loadMap() {
      try {
        const google: any = await gmapsInit();
        const geocoder = new google.maps.Geocoder();
        this.map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: {
              lat: this.mileageIgnoreZone.sourceLatitude,
              lng: this.mileageIgnoreZone.sourceLongitude
            },
            zoom: 15,
            mapTypeControl: false
          }
        );
        // polygon path
        const polygonPath = this.mileageIgnoreZone.detail.coordinates[0].map(
          (coordinate: number[]) => {
            return { lat: coordinate[1], lng: coordinate[0] };
          }
        );
        // Add polygon on map
        const regionPolygon = new google.maps.Polygon({
          paths: polygonPath,
          map: this.map,
          ...this.polygonOptions
        });
        // Setting map to display full polygon via fitBounds()
        const latlngbounds = new google.maps.LatLngBounds();
        for (let i = 0; i < polygonPath.length; i++) {
          latlngbounds.extend(
            new google.maps.LatLng(polygonPath[i].lat, polygonPath[i].lng)
          );
        }
        this.map.fitBounds(latlngbounds);
      } catch (err) {
        notification.error(err.message);
      }
    }
  }
});
