
import Vue from "vue";
import notification from "@/services/notificationService";
import router from "@/router";
import lodash from "lodash";
import MileageIgnoreZonesRightSidebar from "@/components/MileageIgnoreZonesRightSidebar.vue";
import NoTenantSelectedMessage from "@/components/NoTenantSelectedMessage.vue";
export default Vue.extend({
  name: "MileageIgnoreZone",
  components: { MileageIgnoreZonesRightSidebar, NoTenantSelectedMessage },
  data() {
    return {
      tableProps: {
        isPaginated: true,
        isPaginationSimple: false,
        paginationPosition: "bottom",
        defaultSortDirection: "asc",
        sortIcon: "arrow-up",
        sortIconSize: "is-small",
        currentPage: 1,
        perPage: 10
      },
      selected: undefined as undefined | any,
      filteredMileageIgnoreZones: undefined as undefined | any[],
      filters: {
        searchQuery: undefined as undefined | string,
        sort: "descending"
      }
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    mileageIgnoreZones() {
      return this.$store.state.mileageIgnoreZones;
    }
  },
  watch: {
    selectedTenant: function() {
      this.initializeView();
      this.selected = undefined;
    },
    mileageIgnoreZones: function() {
      this.filterMileageIgnoreZones();
      if (this.$route.query.id) {
        const selectedGeoFence = lodash.find(this.mileageIgnoreZones, {
          id: this.$route.query.id
        } as any);
        // To do: Use of any to be changed on lodash update
        this.selected = selectedGeoFence;
      }
    }
  },
  created() {
    this.initializeView();
  },
  methods: {
    initializeView() {
      if (this.selectedTenant) {
        if (this.$route.query.sort) {
          this.filters.sort = this.$route.query.sort.toString();
        }
        if (this.$route.query.search) {
          this.filters.searchQuery = this.$route.query.search.toString();
        }
        this.getMileageIgnoreZones();
      }
    },
    filterMileageIgnoreZones() {
      // adding selected filters as query
      this.$router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          sort: this.filters.sort,
          search: this.filters.searchQuery
        }
      });

      // load MileageIgnoreZones to filter
      this.filteredMileageIgnoreZones = this.mileageIgnoreZones;

      // check if search query
      if (this.filters.searchQuery) {
        this.filteredMileageIgnoreZones = this.filteredMileageIgnoreZones?.filter(
          geoFence => {
            // todo -
            return geoFence.name
              ?.toLowerCase()
              .includes(this.filters.searchQuery!.toLowerCase());
          }
        );
      }
      // If a sorting method is selected
      if (this.filters.sort) {
        if (this.filters.sort === "ascending") {
          this.filteredMileageIgnoreZones = lodash.orderBy(
            this.filteredMileageIgnoreZones,
            ["createdAt"],
            ["asc"]
          ) as any[];
        } else {
          this.filteredMileageIgnoreZones = lodash.orderBy(
            this.filteredMileageIgnoreZones,
            ["createdAt"],
            ["desc"]
          ) as any[];
        }
      }
    },
    showDetail(payload: any) {
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: payload.id
        }
      });
    },
    removeSelection() {
      this.selected = undefined;
      router.replace({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          id: undefined
        }
      });
    },
    getMileageIgnoreZones() {
      this.$store.dispatch("getMileageIgnoreZones", {
        tenant: this.selectedTenant
      });
    }
  }
});
