
import Vue from "vue";
import { filter } from "lodash";
import gmapsInit from "@/services/mapService";
import { validation, helpers } from "@/services/common";
import { MileageIgnoreZoneType } from "@/shared/models";
import notification from "@/services/notificationService";

export default Vue.extend({
  name: "MileageIgnoreZoneMap",
  data() {
    return {
      lodashFilter: filter,
      MileageIgnoreZoneType,
      validation,
      polygonOptions: {
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35
      },
      map: undefined as undefined | any,
      drawingManager: undefined as undefined | any,
      mileageIgnoreZoneType: MileageIgnoreZoneType.Polygon as MileageIgnoreZoneType,
      placesSearchQuery: "",
      cooridnatesQuery: "",
      selectedSearchType: "address",
      filters: {
        vehicle: undefined as undefined | number,
        vehicleSearchQuery: ""
      },
      mileageIgnoreZone: {
        name: undefined as undefined | string,
        description: undefined as undefined | string,
        alertLeavingArea: false,
        alertEnteringArea: false,
        isCreated: false,
        vehicles: [] as string[],
        regionCirlce: undefined as any,
        circleMarker: undefined as any,
        polygonRegion: undefined as any,
        polygonGeoJson: undefined as any,
        radius: 0
      },
      showRadiusErrorMessage: false,
      addressSearchMarker: undefined as any,
      filteredVehicles: undefined as undefined | any
    };
  },
  computed: {
    selectedTenant() {
      return this.$store.state.selectedTenant;
    },
    vehicles() {
      return this.$data.lodashFilter(this.$store.state.vehicles, {
        dongleActive: true
      });
    },
    mileageIgnoreZones() {
      return this.$store.state.mileageIgnoreZones;
    },
    selectedMileageIgnoreZone() {
      return this.$route.params.mileageIgnoreZoneDetail
        ? this.$route.params.mileageIgnoreZoneDetail
        : undefined;
    },
    radius() {
      if (this.$data.mileageIgnoreZone.regionCirlce) {
        return this.$data.mileageIgnoreZone.regionCirlce.getRadius();
      } else {
        return 0;
      }
    },
    defaultTenantLocationSettings() {
      return this.$store.state.defaultTenantLocationSettings;
    },
    editMode() {
      return this.$route.query.id ? true : false;
    }
  },
  watch: {
    mileageIgnoreZoneType: function(newValue: string) {
      if (newValue === MileageIgnoreZoneType.Polygon) {
        this.drawingManager.setMap(this.map);
      } else if (newValue === MileageIgnoreZoneType.Radius) {
        this.drawingManager.setMap(null);
      }
    },
    radius: function(newValue: number) {
      if (newValue > 50) {
        this.mileageIgnoreZone.radius = parseFloat(newValue.toFixed(3));
        // round to nearest 50m
        this.mileageIgnoreZone.regionCirlce.setRadius(newValue);
        // remove radius error message
        this.showRadiusErrorMessage = false;
      } else if (newValue < 50) {
        this.mileageIgnoreZone.radius = 50;
        this.mileageIgnoreZone.regionCirlce.setRadius(50);
        // display radius error message
        this.showRadiusErrorMessage = true;
      }
    },
    cooridnatesQuery: async function(newCoordinates) {
      if (this.mileageIgnoreZone.isCreated && this.placesSearchQuery === "") {
        // check if a previously created mileageIgnoreZone exist
        // this.placesSearchQuery === "" is checked to
        // exclude mileageIgnoreZone created via address search
        this.discardMileageIgnoreZone();
      }
      if (validation.hasValidCoordinates(newCoordinates)) {
        if (this.mileageIgnoreZoneType === MileageIgnoreZoneType.Radius) {
          // draw mileageIgnoreZone on map
          this.createCircle(helpers.extractCoordinates(newCoordinates), 1000);
          this.fitMileageIgnoreZone();
          this.mileageIgnoreZone.isCreated = true;
        } else if (
          this.mileageIgnoreZoneType === MileageIgnoreZoneType.Polygon
        ) {
          const google: any = await gmapsInit();
          this.map.setCenter(helpers.extractCoordinates(newCoordinates));
          this.map.setZoom(16);
          this.addressSearchMarker = new google.maps.Marker({
            position: helpers.extractCoordinates(newCoordinates),
            map: this.map,
            icon: {
              scaledSize: new google.maps.Size(40, 40)
            }
          });
        }
      }
    },
    vehicles: function(vehicles) {
      if (vehicles.length) {
        this.filterVehicleList();
      }
    }
  },
  async created() {
    if (this.selectedMileageIgnoreZone) {
      this.loadMileageIgnoreZone(this.selectedMileageIgnoreZone);
    } else {
      // show Munich
      await this.loadMap(
        {
          lat: 48.1351,
          lng: 11.582
        },
        12
      );
    }
  },
  methods: {
    filterVehicleList() {
      if (this.vehicles) {
        this.filteredVehicles = this.vehicles;
        if (this.filters.vehicleSearchQuery !== "") {
          this.filteredVehicles = this.filteredVehicles?.filter(
            (vehicles: any) => {
              return (
                vehicles.registrationNo
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
                vehicles.nickname
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
                vehicles.make
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase()) ||
                vehicles.model
                  ?.toLowerCase()
                  .includes(this.filters.vehicleSearchQuery!.toLowerCase())
              );
            }
          );
        }
      }
    },
    async createCircle(center: any, radius: number) {
      if (this.selectedSearchType === "coordinates") {
        this.placesSearchQuery = "";
      } else if (this.selectedSearchType === "address") {
        this.cooridnatesQuery = "";
      }
      // function initializes a circle on this.mileageIgnoreZone.regionCirlce and a corresponding marker
      // on this.mileageIgnoreZone.circleMarker as center on the map

      // If a circle already exist unset circle and marker
      if (this.mileageIgnoreZone.regionCirlce) {
        this.mileageIgnoreZone.regionCirlce?.setMap(null);
        this.mileageIgnoreZone.circleMarker?.setMap(null);
      }
      const google: any = await gmapsInit();
      this.mileageIgnoreZone.regionCirlce = new google.maps.Circle({
        ...this.polygonOptions,
        map: this.map,
        center: center,
        radius: radius,
        editable: true
      });
      this.mileageIgnoreZone.regionCirlce.addListener("radius_changed", () => {
        this.fitMileageIgnoreZone();
      });
      // create marker in center
      this.mileageIgnoreZone.circleMarker = new google.maps.Marker({
        position: this.mileageIgnoreZone.regionCirlce.getCenter(),
        map: this.map,
        draggable: true,
        icon: {
          url: require("@/assets/img/map_marker_draggable.svg"),
          scaledSize: new google.maps.Size(40, 40),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(20, 20)
        }
      });
      // add event listener to trigger fitMileageIgnoreZone when the marker is dragged
      this.mileageIgnoreZone.circleMarker.addListener("dragend", () => {
        this.fitMileageIgnoreZone();
      });
      // bind circle's center to marker
      this.mileageIgnoreZone.regionCirlce.bindTo(
        "center",
        this.mileageIgnoreZone.circleMarker,
        "position"
      );
    },
    async getVehicles() {
      await this.$store.dispatch("getVehicles", { archived: false });
    },
    setRadius() {
      if (this.mileageIgnoreZone.radius == 0) {
        this.mileageIgnoreZone.regionCirlce.setRadius(50);
      } else if (
        this.mileageIgnoreZone.regionCirlce &&
        this.mileageIgnoreZone.radius > 0
      ) {
        this.mileageIgnoreZone.regionCirlce.setRadius(
          parseFloat(this.mileageIgnoreZone.radius.toString())
        );
      }
    },
    async loadMileageIgnoreZone(mileageIgnoreZone: Record<string, any>) {
      const google: any = await gmapsInit();
      if (mileageIgnoreZone) {
        // load map based on the selected mileageIgnoreZone for editing
        await this.loadMap(
          {
            lat: 48.1351,
            lng: 11.582
          },
          16
        );
        this.mileageIgnoreZone.name = mileageIgnoreZone.name;
        {
          // polygon path extracted from stored geoJSON
          const polygonPath = mileageIgnoreZone.detail.coordinates[0].map(
            (coordinate: number[]) => {
              return { lat: coordinate[1], lng: coordinate[0] };
            }
          );
          // Add polygon on map
          this.mileageIgnoreZone.polygonRegion = new google.maps.Polygon({
            ...this.polygonOptions,
            paths: polygonPath,
            map: this.map,
            editable: true
          });
          // Setting map to display full polygon via fitBounds()
          const latlngbounds = new google.maps.LatLngBounds();
          for (let i = 0; i < polygonPath.length; i++) {
            latlngbounds.extend(
              new google.maps.LatLng(polygonPath[i].lat, polygonPath[i].lng)
            );
          }
          this.map.fitBounds(latlngbounds, { left: 350 });
        }
        this.mileageIgnoreZone.isCreated = true;
        this.hideDrawingManager();
      }
    },
    submitMileageIgnoreZone() {
      if (this.$route.query.id) {
        // check if the form contains a mileageIgnoreZone to be updated
        this.updateMileageIgnoreZone();
      } else {
        // create new mileageIgnoreZone
        this.addMileageIgnoreZone();
      }
    },
    async addMileageIgnoreZone() {
      const request = {
        name: this.mileageIgnoreZone.name,
        description: this.mileageIgnoreZone.description,
        alertLeavingArea: this.mileageIgnoreZone.alertLeavingArea,
        alertEnteringArea: this.mileageIgnoreZone.alertEnteringArea,
        detail: {},
        vehicles: this.mileageIgnoreZone.vehicles.map(vehicle => {
          return {
            id: vehicle
          };
        }),
        type: "",
        tenant: ""
      };
      // adding mileageIgnoreZone data based on type
      if (this.mileageIgnoreZoneType === MileageIgnoreZoneType.Polygon) {
        request.detail = {
          coordinates: this.mileageIgnoreZone.polygonGeoJson
        };
        request.type = MileageIgnoreZoneType.Polygon;
      } else if (this.mileageIgnoreZoneType === MileageIgnoreZoneType.Radius) {
        request.detail = {
          sourceLatitude: this.mileageIgnoreZone.regionCirlce.getCenter().lat(),
          sourceLongitude: this.mileageIgnoreZone.regionCirlce
            .getCenter()
            .lng(),
          radius: this.mileageIgnoreZone.regionCirlce.getRadius()
        };
        request.type = MileageIgnoreZoneType.Radius;
      }
      request.tenant = this.selectedTenant;
      await this.$store.dispatch("addMileageIgnoreZone", request);
      this.$store.dispatch("getMileageIgnoreZones", request);
      this.$router.push({
        name: "MileageIgnoreZones",
        query: { selectedTenant: this.$route.query.selectedTenant }
      });
    },
    async updateMileageIgnoreZone() {
      const request = {
        name: this.mileageIgnoreZone.name,
        detail: {},
        tenant: this.selectedTenant,
        mileageIgnoreZoneId: this.selectedMileageIgnoreZone.id
      };
      // adding mileageIgnoreZone data based on type
      {
        const vertices = this.mileageIgnoreZone.polygonRegion.getPath();
        this.mileageIgnoreZone.polygonGeoJson = [];
        // populating polygon geoJSON from editable polygon
        for (let i = 0; i < vertices.getLength(); i++) {
          const coordinates = vertices.getAt(i);
          this.mileageIgnoreZone.polygonGeoJson.push([
            coordinates.lng(),
            coordinates.lat()
          ]);
        }

        // checking if first and last point of drawn polygon is the same
        // to retain complete bounding box, drawing manager fails to
        // maintain this if a new point is added during editing the polygon
        if (
          JSON.stringify(this.mileageIgnoreZone.polygonGeoJson[0]) !==
          JSON.stringify(
            this.mileageIgnoreZone.polygonGeoJson[
              this.mileageIgnoreZone.polygonGeoJson.length - 1
            ]
          )
        ) {
          // if the first and last points are not the same, append first
          // point to the array to complete the bounding box
          this.mileageIgnoreZone.polygonGeoJson.push(
            this.mileageIgnoreZone.polygonGeoJson[0]
          );
        }

        request.detail = {
          coordinates: [this.mileageIgnoreZone.polygonGeoJson]
        };
      }
      const mileageIgnoreZoneId = this.$route.query.id.toString();
      await this.$store.dispatch("updateMileageIgnoreZone", request);
      this.$router.push({
        name: "MileageIgnoreZones",
        query: { id: this.selectedMileageIgnoreZone.id }
      });
    },
    async loadMap(center: { lat: number; lng: number }, zoom: number) {
      try {
        const google: any = await gmapsInit();
        const geocoder = new google.maps.Geocoder();
        this.map = new google.maps.Map(
          document.getElementById("map") as HTMLElement,
          {
            center: center,
            zoom: zoom,
            mapTypeControl: false
          }
        );
        // Add Drawing manager
        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              google.maps.drawing.OverlayType.POLYGON,
              google.maps.drawing.OverlayType.RECTANGLE
            ]
          },
          polygonOptions: {
            ...this.polygonOptions,
            clickable: false,
            editable: true,
            zIndex: 1
          },
          rectangleOptions: {
            ...this.polygonOptions,
            clickable: false,
            editable: true,
            zIndex: 1
          }
        });
        this.drawingManager.setMap(this.map);
        // create data layer
        const dataLayer = new google.maps.Data();
        // listen to overlay complete event
        google.maps.event.addListener(
          this.drawingManager,
          "overlaycomplete",
          (event: any) => {
            if (!this.mileageIgnoreZone.polygonRegion) {
              // store event overlay
              this.mileageIgnoreZone.polygonRegion = event.overlay;
              switch (event.type) {
                case google.maps.drawing.OverlayType.RECTANGLE: {
                  const b = event.overlay.getBounds();
                  const p = [
                    b.getSouthWest(),
                    {
                      lat: b.getSouthWest().lat(),
                      lng: b.getNorthEast().lng()
                    },
                    b.getNorthEast(),
                    {
                      lng: b.getSouthWest().lng(),
                      lat: b.getNorthEast().lat()
                    }
                  ];
                  dataLayer.add(
                    new google.maps.Data.Feature({
                      geometry: new google.maps.Data.Polygon([p])
                    })
                  );
                  break;
                }
                case google.maps.drawing.OverlayType.POLYGON:
                  dataLayer.add(
                    new google.maps.Data.Feature({
                      geometry: new google.maps.Data.Polygon([
                        event.overlay.getPath().getArray()
                      ])
                    })
                  );
                  break;
              }
              // save geoJSON to polygon geoJSON
              dataLayer.toGeoJson((obj: any) => {
                // dataLayer can hold multiple features
                // since drawing is truncated on completion of first one
                // geometry of drawn polygon is at features[0]
                this.mileageIgnoreZone.polygonGeoJson =
                  obj.features[0].geometry.coordinates;
              });
              // hide drawing options
              this.drawingManager.setMap(null);
              // set mileageIgnoreZone created to true
              this.mileageIgnoreZone.isCreated = true;
            }
          }
        );
        // Add option to draw circle on map
        this.map.addListener("click", async (event: { latLng: any }) => {
          if (!this.mileageIgnoreZone.isCreated) {
            await this.createCircle(event.latLng, 1000);
            this.fitMileageIgnoreZone();
            this.mileageIgnoreZone.isCreated = true;
          }
        });
        this.initPlacesSearch();
      } catch (err) {
        notification.error(err.message);
      }
    },
    async initPlacesSearch() {
      try {
        const google: any = await gmapsInit();

        // Create the search box and link it to the UI element.
        const input = document.getElementById(
          "places-search"
        ) as HTMLInputElement;
        const searchBox = new google.maps.places.SearchBox(input);

        // Bias the SearchBox results towards current map's viewport.
        this.map.addListener("bounds_changed", () => {
          searchBox.setBounds(this.map.getBounds());
        });

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();

          if (places.length == 0) {
            return;
          }

          places.forEach(
            (place: {
              geometry: { location: any; viewport: any };
              icon: string;
              name: string;
              formatted_address: string;
            }) => {
              if (!place.geometry || !place.geometry.location) {
                // Returned place contains no geometry
                return;
              } else if (
                this.mileageIgnoreZoneType === MileageIgnoreZoneType.Radius
              ) {
                // if mileageIgnoreZoneType is radius draw a cricle on searched address

                // if previously searched address exist remove it
                if (this.addressSearchMarker) {
                  this.addressSearchMarker.setMap(null);
                }
                this.createCircle(place.geometry.location, 1000);
                this.fitMileageIgnoreZone();
                this.mileageIgnoreZone.isCreated = true;
              } else if (
                this.mileageIgnoreZoneType === MileageIgnoreZoneType.Polygon
              ) {
                // if mileageIgnoreZoneType is polygon move the map on searched address

                // if previously searched address exist remove it
                if (this.addressSearchMarker) {
                  this.addressSearchMarker.setMap(null);
                }
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(15);
                this.addressSearchMarker = new google.maps.Marker({
                  position: place.geometry.location,
                  map: this.map,
                  icon: {
                    scaledSize: new google.maps.Size(40, 40)
                  }
                });
              }
            }
          );
        });
      } catch (err) {
        notification.error(err.message);
      }
    },
    discardMileageIgnoreZone() {
      if (this.mileageIgnoreZoneType === MileageIgnoreZoneType.Radius) {
        this.mileageIgnoreZone.regionCirlce.setMap(null);
        this.mileageIgnoreZone.circleMarker.setMap(null);
      } else if (this.mileageIgnoreZoneType === MileageIgnoreZoneType.Polygon) {
        // hide drawn polygon from map
        this.mileageIgnoreZone.polygonRegion.setMap(null);
        // remove stored polygon
        this.mileageIgnoreZone.polygonRegion = undefined;
        // clear stored geo json
        this.mileageIgnoreZone.polygonGeoJson = undefined;
        // enable drawing tools on map
        this.drawingManager.setMap(this.map);
      }
      if (this.addressSearchMarker) {
        this.addressSearchMarker.setMap(null);
      }
      this.mileageIgnoreZone.isCreated = false;
      this.mileageIgnoreZone.vehicles = [];
      this.placesSearchQuery = "";
      if (this.mileageIgnoreZone.regionCirlce) {
        this.mileageIgnoreZone.regionCirlce.setMap(null);
      }
      if (this.mileageIgnoreZone.circleMarker) {
        this.mileageIgnoreZone.circleMarker.setMap(null);
      }
      this.showRadiusErrorMessage = false;
      // re initialize places search (google autocomplete)
      this.initPlacesSearch();
    },
    async fitMileageIgnoreZone() {
      const google: any = await gmapsInit();
      const bounds = new google.maps.LatLngBounds();
      bounds.union(this.mileageIgnoreZone.regionCirlce.getBounds());
      // padding left: 20% (window.innerWidth * 0.2) to prevent the
      // circle from going under the form overlay
      this.map.fitBounds(bounds, { left: window.innerWidth * 0.2 });
    },
    hideDrawingManager() {
      if (this.drawingManager) {
        this.drawingManager.setMap(null);
      }
    }
  }
});
